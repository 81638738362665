<template>
  <div class="products">
    <div
      class="products__block col-11 col-sm-10 m-auto"
      data-aos="fade-up"
      data-aos-duration="500"
      data-aos-offset="300"
    >
      <div
        class="flex"
        v-for="(items, index) in [webCloud, bimCad]"
        :key="index"
      >
        <div class="products__content__title">
          {{ items.title }}
        </div>
        <ul>
          <li v-for="(item, idx) in items.items" :key="idx">
            <a :href="item.link" target="_blank">{{ item.content }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class=""><Footer /></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
const Footer = () => import("../views/FooterNew.vue");

export default {
  name: "Products",
  data() {
    return {
      webCloud: this.$t("products").webCloud,
      bimCad: this.$t("products").bimCad,
    };
  },

  created() {},

  computed: {
    ...mapState({
      lang: (state) => state.langs.lang,
    }),
  },
  watch: {
    lang() {
      this.webCloud = this.$t("products").webCloud;
      this.bimCad = this.$t("products").bimCad;
    },
  },
  methods: {},
  components: { Footer },
};
</script>

<style lang="scss" scoped>
.products {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 100vh;
  .products__block {
    padding: 150px 0;
    flex: 1;
    display: flex;
    gap: 100px;
    justify-content: center;
  }
  .products__content__title {
    position: relative;
    font-weight: 700;
    font-size: 40px;
    color: var(--blue-text);
    z-index: 0;
    margin: 20px 0;
  }
  a {
    color: black !important;
    &:hover {
      opacity: 0.8;
      transition: all 0.2s linear;
    }
  }
  @media (max-width: 1280px) {
    height: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
    .products__content__title {
      font-size: 32px;
    }
    .products__block {
      padding: 100px 0;
      flex: 1;
      display: flex;
      gap: 30px;
      flex-direction: column;
    }
  }
}
</style>
